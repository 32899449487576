.row {
    flex-direction: row;
}

.row-r {
    flex-direction: row-reverse;
}

.flex-r {
    display: flex;
    flex-direction: row-reverse;
}

.col {
    display: flex;
    flex-direction: column;
}

.col-r {
    display: flex;
    flex-direction: column-reverse;
}

.flex {
    display: flex;
    flex-direction: row;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.justify-around {
    display: flex;
    justify-content: space-around;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-start {
    display: flex;
    align-items: flex-start;
}

.flex-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-center-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-center-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-center-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-start-center {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.flex-start-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-start-end {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.flex-start-around {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

.flex-start-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.flex-end-center {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.flex-end-start {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.flex-end-end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.flex-end-around {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

.flex-end-between {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.z-1 {
    z-index: -100;
}

.z0 {
    z-index: 0;
}

.z1 {
    z-index: 100;
}

.z2 {
    z-index: 200;
}

.z3 {
    z-index: 300;
}

.z4 {
    z-index: 400;
}

.z5 {
    z-index: 500;
}

.z6 {
    z-index: 600;
}

.z7 {
    z-index: 700;
}

.z8 {
    z-index: 800;
}

.z9 {
    z-index: 900;
}

.z10 {
    z-index: 1000;
}

.z11 {
    z-index: 1100;
}

.ant-card-body {
    padding: 16px !important;
}

.w25 {
    width: 25%;
}

.w100 {
    width: 100%;
}

.h300px {
    height: 300px;
}

.border-r {
    border-right: solid #F0F2F5 1px;
}

.border {
    border: solid #F0F2F5 1px;
}

.h3 {
    display: block;
    font-size: 1.07em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

#insightsView p {
    color: #6B7483;
}

#insightsView .active-bg p {
    color: #ffffff;
}

.front,
.back {
    height: 300px;
    width: 100%;
    overflow: hidden;
    backface-visibility: hidden;
    position: absolute;
    transition: transform .6s linear;
}

.front {
    transform: perspective(600px) rotateY(0deg);
}

.back {
    transform: perspective(600px) rotateY(-180deg);
}
